<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="1" style="max-width: 1800px" size="small">
            <a-step :title="$t('logistics.consignment_create_order')" />
            <a-step :title="$t('logistics.add_goods')" />
            <a-step :title="$t('logistics.select_logistics')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-2 mb-3">
        <a-typography-text strong>{{
          $t("logistics.order_info")
        }}</a-typography-text>
      </div>
      <a-descriptions>
        <a-descriptions-item :label="$t('logistics.relation_order_no')">
          <CPlanNumberItem :no="cache.relationOrderNo"></CPlanNumberItem>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('logistics.source_of_sales')">{{
          cache.selectedSourceSales
        }}</a-descriptions-item>
        <a-descriptions-item
          :label="$t('logistics.recipients')"
          v-if="
            cache.selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          "
        >
          {{ cache.recipients }} {{ cache.contactNumber }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('logistics.shipments_warehouse')">{{
          cache.consignmentWarehouseNo
        }}</a-descriptions-item>
        <a-descriptions-item
          :label="$t('logistics.receiving_address')"
          v-if="
            cache.selectedAgentOrderLogisticsType ==
            agentOrderLogisticsTypeEnum.seLogistics
          "
          :span="2"
        >
          {{ getAddress(cache.addressData) }}
        </a-descriptions-item>
      </a-descriptions>

      <div class="table">
        <a-button
          type="primary"
          @click="handleShowModalProduct"
          class="mb-1"
          ghost
          >{{ $t("warehouse.add_product") }}</a-button
        >
        <a-table
          :columns="columns"
          :data-source="cache.producTableList"
          :scroll="{ x: true }"
          size="small"
          style="min-height: 223px"
          :pagination="false"
        >
          <template #serialNumber>
            <span>
              {{ $t("warehouse.serial_number") }}
            </span>
          </template>
          <template #productNameAndSeSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
            </span>
          </template>
          <template #packagingSize>
            <span>
              {{ $t("logistics.business_packaging_size") }}
            </span>
          </template>
          <template #weight>
            <span>
              {{ $t("logistics.weight") }}
            </span>
          </template>
          <template #amount>
            <span>
              {{ $t("logistics.count") }}
            </span>
          </template>

          <template #productNameAndSeSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.picture" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #packagingSizeCustom="{ record }">
            <span v-if="!record.length || !record.width || !record.height"
              >-</span
            >
            <span v-else
              >{{ record.length }}x{{ record.width }}x{{
                record.height
              }}
              cm</span
            >
          </template>

          <template #amountCustom="{ record }">
            <a-input-number
              v-model:value="record.amount"
              :step="1"
              :defaultValue="1"
              :precision="0"
              :min="1"
              :max="record.usableInventory"
            />
          </template>

          <template #operateCustom="{ record }">
            <a-button @click="handleDelete(record)" type="primary" ghost>{{
              $t("common.remove")
            }}</a-button>
          </template>
        </a-table>
      </div>
      <a-row type="flex" justify="space-between" v-if="cache.selectedAgentOrderLogisticsType != agentOrderLogisticsTypeEnum.destruction">
        <!-- 选择包装材料 -->
        <a-col>
          <div>
            <b>{{ $t("warehouse.packing_methods") }}</b>
          </div>
          <a-tabs
            v-model:activeKey="cache.packMaterialType"
            @change="handelChangeSelectPackagingType"
          >
            <a-tab-pane
              :key="packMaterialTypeEnum.outPacking"
              :tab="$t('warehouse.additional_packing_material_selection')"
            >
              <div class="mt-3">
                <a-select
                  v-model:value="cache.selectedPackagingType"
                  @change="handelChangeSelectPackagingType"
                  :disabled="totalProductCount < 1"
                  :placeholder="$t('common.please_select')"
                  style="min-width: 300px"
                >
                  <!-- 产品数量大于1就不能选"无须额外包装",但可以不选 -->
                  <a-select-option v-if="totalProductCount < 2" value="">{{
                    $t("warehouse.no_additional_packaging_required")
                  }}</a-select-option>
                  <a-select-option
                    v-for="item in packagingTypeList"
                    :key="item.key"
                    :value="item.id"
                    :title="
                      item.name +
                      '-' +
                      getText(item) +
                      (!item.length || !item.width || !item.width
                        ? ''
                        : '-' +
                          item.length +
                          'x' +
                          item.width +
                          'x' +
                          item.height +
                          'cm')
                    "
                  >
                    {{ item.name }}-{{ getText(item) }}
                    {{
                      !item.length || !item.width || !item.width
                        ? ""
                        : "-" +
                          item.length +
                          "x" +
                          item.width +
                          "x" +
                          item.height +
                          "cm"
                    }}
                  </a-select-option>
                </a-select>
              </div>
              <div>
                <span
                  class="text-warning"
                  v-if="totalProductCount > 1 && !cache.selectedPackagingType"
                >
                  {{
                    $t(
                      "warehouse.when_the_total_number_of_products_is_greater_than_1_this_option_is_mandatory"
                    )
                  }}
                </span>
              </div>
              <div
                class="mt-2"
                v-if="
                  cache.selectedPackagingInfo && cache.selectedPackagingInfo.id
                "
              >
                <div>
                  {{ $t("logistics.size") }}:{{
                    !cache.selectedPackagingInfo.length ||
                    !cache.selectedPackagingInfo.width ||
                    !cache.selectedPackagingInfo.width
                      ? ""
                      : cache.selectedPackagingInfo.length +
                        "x" +
                        cache.selectedPackagingInfo.width +
                        "x" +
                        cache.selectedPackagingInfo.height +
                        " cm"
                  }}
                </div>
                <div>
                  {{ $t("logistics.max_capacity") }}:
                  {{ cache.selectedPackagingInfo.maxVolume }} m<sup>3</sup>
                </div>
                <div>
                  {{ $t("warehouse.max_volume_weight") }}:
                  {{ cache.selectedPackagingInfo.packBearingQuantity }} kg
                </div>
                <div>
                  {{ $t("logistics.weight_packing_material") }}:
                  {{ cache.selectedPackagingInfo.weight }} kg
                </div>
                <div>
                  {{ $t("logistics.cost") }}:
                  {{ cache.selectedPackagingInfo.feeUnit
                  }}{{
                    $filters.amountToFixed2(cache.selectedPackagingInfo.fee)
                  }}
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="packMaterialTypeEnum.mergePackaging">
              <template #tab>
                {{
                  $t(
                    $enumLangkey(
                      "packMaterialType",
                      packMaterialTypeEnum.mergePackaging
                    )
                  )
                }}
                <CTooltip :title="$t('warehouse.merge_packaging_tips')" />
              </template>
              <div>
                <span>{{ $t("warehouse.merge_packaging_fee") }}: </span>
                <span
                  v-if="
                    cache.mergePackageData && cache.mergePackageData.operateFee
                  "
                  >{{ cache.mergePackageData.currencySymbol
                  }}{{
                    $filters.amountToFixed2(
                      cache.mergePackageData.operateFee?.amount
                    )
                  }}</span
                >
                <span v-else class="text-warning">{{
                  $t("warehouse.merge_packaging_not_supported")
                }}</span>
              </div>
            </a-tab-pane>
            <a-tab-pane :key="packMaterialTypeEnum.stretchFilmPackaging"
              :tab="$t('warehouse.wrapping_film')"
            >
              {{$t('warehouse.wrapping_film_fee')}}: 
               <span
                  v-if="
                    cache.stretchFilmPackaging && cache.stretchFilmPackaging?.operateFee
                  "
                  >
                  {{ cache.stretchFilmPackaging.currencySymbol}}
                  {{
                    $filters.amountToFixed2(
                      cache.stretchFilmPackaging.operateFee?.amount
                    )
                  }}
               </span>
               <span v-else> - </span>
              <span v-if="!cache.stretchFilmPackaging" class="text-warning">
                {{
                  $t("warehouse.not_supported")
                }}
              </span>
              <div>{{$t('warehouse.supports_up_to_p0_single_products',[wrappingFilmMaxCount])}}
                </div>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
      <!-- button创建计划 -->
      <div class="mt-3 mb-3">
        <a-row type="flex" justify="end">
          <a-col class="mr-3">
            <a-button type="primary" @click="handleBackStep" ghost>{{
              $t("common.back_step")
            }}</a-button>
          </a-col>
          <a-col class="mr-3">
            <a-button
              type="primary"
              :loading="loading"
              @click="handleNextStep"
              >{{ $t("warehouse.created_plan") }}</a-button
            >
          </a-col>
        </a-row>
      </div>

      <a-modal
        width="700px"
        v-model:visible="isShowModalAddProduct"
        :centered="true"
        :maskClosable="false"
        :ok-button-props="{ hidden: true }"
        :cancelText="$t('common.close')"
        :title="
          $t('warehouse.select_consignment') +
          '(' +
          cache.consignmentWarehouseNo +
          ')'
        "
      >
        <a-form layout="inline">
          <a-form-item class="mb-1">
            <SearchProduct ref="refSearchProduct"></SearchProduct>
          </a-form-item>
          <a-form-item class="mb-1">
            <a-button
              type="primary"
              :loading="addProductModalLoading"
              @click="hanldeSearchProducts"
              >{{ $t("common.query") }}</a-button
            >
          </a-form-item>
        </a-form>
        <a-table
          :columns="modalColumns"
          :data-source="producTableListModal"
          :scroll="{ x: 500, y: 1000 }"
          :pagination="false"
          size="small"
          :loading="addProductModalLoading"
        >
          <template #productNameAndSeSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
            </span>
          </template>
          <template #usableInventory>
            <span>
              {{ $t("logistics.usable_inventory") }}
            </span>
          </template>

          <template #productNameAndSeSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.picture"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #addButtonCustom="{ record }">
            <div class="ant-form-inline">
              <a-button
                @click="handleAddProductToList(record)"
                :disabled="
                  existsProduc(record) || record.usableInventory <= 0
                "
                type="primary"
                :loading="buttonAddProductLoading"
                ghost
                >{{ $t("common.add") }}</a-button
              >
            </div>
          </template>
        </a-table>
        <a-row type="flex" justify="space-around" align="middle" class="mt-3">
          <a-col>
            <CPager
              @handlePage="handlePage"
              :showLessItems="true"
              :pageSizeOptions="['5', '10', '30']"
              :page-data="pageData"
            ></CPager>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        width="700px"
        v-model:visible="applyingForQuotationModal.visible"
        :centered="true"
        :maskClosable="false"
        :ok-button-props="{ hidden: true }"
        :cancelText="$t('common.close')"
        :title="$t('待操作')"
      >
        <a-row :gutter="[12, 6]">
          <a-col>
            {{ $t("warehouse.mexican_tax_reporting_news_1") }}
          </a-col>
          <a-col :span="24">
            {{ $t("warehouse.mexican_tax_reporting_news_2") }}({{
              $t("common.country")
            }}:
            <strong
              >{{
                getLanguageName({
                  cnName: applyingForQuotationModal.countryCnName,
                  enName: applyingForQuotationModal.countryEnName,
                })
              }} </strong
            >)
          </a-col>
          <a-col :span="24">
            <strong
              >{{ $t("warehouse.products_that_need_to_be_updated") }}:</strong
            >
          </a-col>
          <a-col
            v-for="(item, index) in applyingForQuotationModal.seSkuS"
            :key="index"
          >
            {{ index + 1 }}.
            <router-link
              :to="{ name: 'product_edit', params: { id: item.productId } }"
            >
              {{ item.seSku }}
            </router-link>
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, computed, watch } from "vue";
import {
  Row,
  Col,
  Steps,
  Typography,
  Table,
  Descriptions,
  Input,
  InputNumber,
  Select,
  Button,
  Form,
  Modal,
  Space,
  message,
  Radio,
  Tabs,
  Checkbox
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CImage from "@/views/components/CImage.vue";
import SearchProduct from "@/views/components/SearchProduct.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { consignmentKeys } from "../../../consts/index";
import { useTab } from "../../../hooks/tabs/index";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import {
  getAddressByLanguageV2,
  getName,
  gToKg,
  cmCubicToM,
  getEnumLangkey,
} from "../../../utils/general";
import {
  getInventoryProductList,
  getPackagingType,
  checkVolumeAvailable,
  quotationVerificationProducts,
  getMaxWrappingFilmNumber,
} from "../../../api/modules/consignment/index";
import {
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum,
  packMaterialType as packMaterialTypeEnum,
} from "@/enum/enum.json";

export default {
  name: "consignment_order_add_product",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ATypographyText: Typography.Text,
    AInput: Input,
    AInputNumber: InputNumber,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    AModal: Modal,
    ASpace: Space,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ACheckbox: Checkbox,
    Content,
    CImage,
    CPager,
    CTooltip,
    SearchProduct,
    CPlanNumberItem,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const { getters } = useStore();
    const refSearchProduct = ref();

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 150,
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
          customRender: "packagingSizeCustom",
        },
      },
      {
        dataIndex: "weight",
        slots: {
          title: "weight",
        },
      },
      {
        dataIndex: "amount",
        slots: {
          title: "amount",
          customRender: "amountCustom",
        },
      },
      {
        title: "",
        dataIndex: "operate",
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const modalColumns = [
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 150,
      },
      {
        dataIndex: "usableInventory",
        slots: {
          title: "usableInventory",
        },
      },
      {
        title: "",
        dataIndex: "addButton",
        slots: {
          customRender: "addButtonCustom",
        },
        width: 150,
      },
    ];

    const refPage = ref();
    const state = reactive({
      loading: false,
      isShowModalAddProduct: false,
      addProductModalLoading: false,
      buttonAddProductLoading: false,
      selectedAgentOrderLogisticsType: null,
      producTableListModal: [],
      modalSearchValue: "",
      isShowPackingMaterialDetails: false,
      cache: {
        relationOrderNo: "", //关联订单号
        selectedSourceSales: null, //销售来源
        recipients: "", //收件人
        contactNumber: "", //联系电话
        consignmentWarehouseNo: null, //发货仓
        selectedConsignmentWarehouse: null, //发货仓库Id
        selectedConsignmentWarehouseInfo: null, // 发货仓库
        addressData: {}, //运送地址

        producTableList: [],
        selectedPackagingType: null, //选择的附加包装材料的类型
        selectedPackagingInfo: {},
        packMaterialType: packMaterialTypeEnum.outPacking, //选择的包装方式的类型
        mergePackageData: null,
        stretchFilmPackaging: null,
      },
      mergePackageDisabled: true,
      // 外包装材料
      packagingTypeList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5,
      },
      applyingForQuotationModal: {
        visible: false,
        countryCnName: null,
        countryEnName: null,
        currencyCode: null,
        seSkuS: [],
      },
      wrappingFilmMaxCount:0
    });

    const handleDelete = async (record) => {
      state.cache.producTableList = state.cache.producTableList.filter(
        (item) => item.productId !== record.productId
      );
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleGetProducts();
    };

    const hanldeSearchProducts = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      handleGetProducts();
    };

    const handleGetProducts = async () => {
      state.addProductModalLoading = true;
      const searchData = {
        warehouseId: state.cache.selectedConsignmentWarehouse,
        productId: refSearchProduct.value
          ? refSearchProduct.value.selectedProductId
          : "",
        ...state.pageData,
      };
      getInventoryProductList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.producTableListModal = items.map((x, index) => {
              return {
                key: index,
                productId: x.productId,
                picture: x.imgUrl,
                productName: x.productName,
                seSku: x.seSku,
                productNo: x.productNo,
                usableInventory: parseInt(x.count) ?? 0,
                length: x.packingLength,
                width: x.packingWidth,
                height: x.packingHeight,
                weight: gToKg(x.packingWeight) + "kg",
                transportCharacteristics: x.transportCharacteristics,
                amount: 1,
                warehouseId: state.cache.selectedConsignmentWarehouse,
              };
            });
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
            state.producTableListModal = [];
          }
          state.addProductModalLoading = false;
        })
        .catch(() => {
          state.addProductModalLoading = false;
        });
    };

    const existsProduc = (record) => {
      return (
        state.cache.producTableList.findIndex(
          (x) => x.productId == record.productId
        ) != -1
      );
    };

    const handleAddProductToList = async (record) => {
      state.buttonAddProductLoading = true;
      if (!existsProduc(record)) {
        let waitAddProduct = state.producTableListModal.filter(
          (x) => x.productId == record.productId
        );
        state.cache.producTableList =
          state.cache.producTableList.concat(waitAddProduct);
          
        message.success(i18n.t("common.succeed"));
      }
      state.buttonAddProductLoading = false;
    };

    const handleShowModalProduct = async () => {
      state.isShowModalAddProduct = true;
      if (state.producTableListModal.length == 0) {
        handleGetProducts();
      }
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getText = (item) => {
      return getName(
        { cnName: item.chMaterial, enName: item.enMaterial },
        getters.language
      );
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    //处理材料改变
    const handelChangeSelectPackagingType = () => {
      state.isShowPackingMaterialDetails = false;
      if (state.cache.producTableList?.length > 0 ) {
        if (
          state.cache.selectedPackagingType ||
          state.cache.packMaterialType === packMaterialTypeEnum.mergePackaging ||
          state.cache.packMaterialType === packMaterialTypeEnum.stretchFilmPackaging 
        ) {
          let productDatas = state.cache.producTableList.map((x) => {
            return {
              productId: x.productId,
              outCount: x.amount,
            };
          });

          let pra = {
            packMaterialId: state.cache.selectedPackagingType,
            warehouseId: state.cache.selectedConsignmentWarehouse,
            packMaterialType: state.cache.packMaterialType,
            productDatas: productDatas,
          };
          checkVolumeAvailable(pra)
            .then(({ result }) => {
              if (
                state.cache.packMaterialType === packMaterialTypeEnum.outPacking
              ) {
                // keep
              } else if (
                state.cache.packMaterialType ===
                packMaterialTypeEnum.mergePackaging
              ) {
                if (result && result.mergePackage) {
                  state.cache.mergePackageData = result.mergePackage;
                } else {
                  state.cache.mergePackageData = null;
                }
              } else if (
                state.cache.packMaterialType ===
                packMaterialTypeEnum.stretchFilmPackaging
              ) {
                if (result && result.stretchFilmPackaging) {
                  state.cache.stretchFilmPackaging = result.stretchFilmPackaging;
                } else {
                  state.cache.stretchFilmPackaging = null;
                }
              }
            })
            .catch(() => {
              if (
                state.cache.packMaterialType === packMaterialTypeEnum.outPacking
              ) {
                state.cache.selectedPackagingType = null;
              }
            })
        } else {
          //ignore
        }
      } else {
        state.cache.mergePackageData = null;
        state.cache.selectedPackagingType = null;
        state.cache.stretchFilmPackaging = null;
      }
    };

    const handleBackStep = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_order_create" });
    };

    const handleNextStep = async () => {
      // 销毁不需要包装
      if (state.cache.selectedAgentOrderLogisticsType != agentOrderLogisticsTypeEnum.destruction) {
        // 判断附加包装材料/合并包装
        if (state.cache.packMaterialType === packMaterialTypeEnum.outPacking) {
          if (totalProductCount.value > 1 && !state.cache.selectedPackagingType) {
            message.error(
              i18n.t("common.p0_is_required", [
                i18n.t("warehouse.additional_packing_material_selection"),
              ])
            );
            return;
          }
        } else if (
          state.cache.packMaterialType === packMaterialTypeEnum.mergePackaging
        ) {
          if (!state.cache.mergePackageData) {
            // message.error(i18n.t("common.p0_is_required", [i18n.t(getEnumLangkey('packMaterialType', packMaterialTypeEnum.mergePackaging))]));
            message.error(i18n.t("warehouse.merge_packaging_not_supported"));
            return;
          }
        } else if (
          state.cache.packMaterialType === packMaterialTypeEnum.stretchFilmPackaging
        ) {
          if (!state.cache.stretchFilmPackaging) {
            message.error(i18n.t("warehouse.not_supported_wrapping_film"));
            return;
          }
        }
      }

      if (state.cache.producTableList?.length == 0) {
        message.error(i18n.t("warehouse.please_add_products"));
        return false;
      }

      let checkRequired =
        state.cache.producTableList.findIndex((x) => !x.amount) > -1;
      if (checkRequired) {
        message.error(i18n.t("warehouse.table_in_amount_is_required"));
        return false;
      }

      // 销毁不需要验证
      if (state.cache.selectedAgentOrderLogisticsType == agentOrderLogisticsTypeEnum.destruction) {
        setObjectSessionStorage(
          consignmentKeys.createConsignmentOrderData,
          state.cache
        );
        delVisitedRoute(router.currentRoute.value);
        router.push({ path: "/consignment/order/selectlogistics" });
      } else {
        let productIds = state.cache?.producTableList?.map((x) => {
          return x.productId;
        });

        let parameter = {
          warehouseId: state.cache?.selectedConsignmentWarehouseInfo?.warehouseId,
          receiveCountryId: state.cache?.addressData?.selectedCountrys,
          productIds: productIds,
        };

        state.loading = true;
        quotationVerificationProducts(parameter)
          .then(({ result }) => {
            if (result?.isSuccess) {
              setObjectSessionStorage(
                consignmentKeys.createConsignmentOrderData,
                state.cache
              );
              delVisitedRoute(router.currentRoute.value);
              router.push({ path: "/consignment/order/selectlogistics" });
            } else {
              state.applyingForQuotationModal.visible = true;
              state.applyingForQuotationModal.seSkuS = result.data;
              state.applyingForQuotationModal.countryCnName =
                result.countryCnName;
              state.applyingForQuotationModal.countryEnName =
                result.countryEnName;
              state.applyingForQuotationModal.currencyCode = result.currencyCode;
            }
            state.loading = false;
          })
          .catch(() => {
            state.loading = false;
          });
      }
    };

    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(
        consignmentKeys.createConsignmentOrderData
      );
      if (cacheData) {
        Object.assign(state.cache, cacheData);
        //预防第一次选了产品，返回上一步后重新选择了仓库了，导致产品不存在的情况。
        state.cache.producTableList = (
          state.cache.producTableList || []
        ).filter(
          (x) => x.warehouseId == state.cache.selectedConsignmentWarehouse
        );
        funcGetPackagingType();
      }
    };

    const funcGetPackagingType = async () => {
      try {
        let pra = {
          warehouseId: state.cache.selectedConsignmentWarehouse,
        };
        // 获取外包装材料
        let { result: packagingType } = await getPackagingType(pra);
        if (Array.isArray(packagingType)) {
          state.packagingTypeList = packagingType.map((x, index) => {
            return {
              key: index,
              id: x.id,
              name: x.packMaterialName,
              chMaterial: x.materialTypeCnName,
              enMaterial: x.materialTypeEnName,
              length: x.packMaterialLength,
              width: x.packMaterialWidth,
              height: x.packMaterialHeight,
              weight: gToKg(x.packMaterialWeight), //材料重量
              packBearingQuantity: gToKg(x.packBearingQuantity), //最大容重
              maxVolume: cmCubicToM(x.packMaterialVolume), //材料最大承重
              maxBearingQuantity: gToKg(x.packBearingQuantity),
              fee: x.price,
              feeUnit: x.currencySymbol,
            };
          });
        }
      } catch (error) {
      } finally {
      }
    };

    //添加的产品的总数量
    const totalProductCount = computed(() => {
      let total = state.cache.producTableList.reduce((total, item) => {
        return total + (item?.amount ?? 0);
      }, 0);
      if (total > 1) {
        if (state.cache.selectedPackagingType === "") {
          state.cache.selectedPackagingType = null;
        }
      }
      return total;
    });

    //重置包装材料选择
    watch(
      () => state.cache.producTableList,
      () => {
        state.cache.selectedPackagingType = null;
        handelChangeSelectPackagingType();
      },
      { deep: true, immediate: true }
    );

    //包装材料详情
    watch(
      () => [state.cache.selectedPackagingType, state.packagingTypeList],
      (newValue) => {
        let res = {};
        if (state.packagingTypeList && state.cache.selectedPackagingType) {
          res = state.packagingTypeList.find(
            (x) => x.id == state.cache.selectedPackagingType
          );
        }
        state.cache.selectedPackagingInfo = res;
      },
      { immediate: true }
    );

    const funcGetMaxWrappingFilmNumber = ()=>{
      getMaxWrappingFilmNumber().then((result)=>{
        state.wrappingFilmMaxCount = result.result;
      })
    }

    onMounted(async () => {
      getCacheData();
      funcGetMaxWrappingFilmNumber();
    });

    onBeforeRouteLeave((to, from, next) => {
      state.applyingForQuotationModal.visible = false;
      const orderRoutes = [
        "/consignment/order/create",
        "/consignment/order/addproduct",
        "/consignment/order/selectlogistics",
      ];
      if (!orderRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => orderRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(
            consignmentKeys.createConsignmentOrderData
          );
        }
      }
      next();
    });

    return {
      getLanguageName,
      modalColumns,
      columns,
      refPage,
      ...toRefs(state),
      gToKg,
      getAddress,
      getText,
      agentOrderLogisticsTypeEnum,
      packMaterialTypeEnum,
      refSearchProduct,
      hanldeSearchProducts,
      totalProductCount,
      existsProduc,
      handlePage,
      handleDelete,
      handleAddProductToList,
      handleGetProducts,
      handleShowModalProduct,

      handleBackStep,
      handleNextStep,
      handelChangeSelectPackagingType,
    };
  },
};
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
</style>
